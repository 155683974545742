<template>
    <div ref="hoverElement" class="popover-personinfo bs-popover-auto fade" role="tooltip">
        <div v-if="isLoaded" class="popover-header p-2">
            <div class="fw-bold">
                <small>{{personInfo.Name}} (ID: {{personInfo.ID}})</small>
            </div>
        </div>
        <h3 v-else class="popover-header p-2">
            {{$t('Loading...')}}
        </h3>

        <div class="popover-body p-2">
            <template v-if="isLoaded">
                <div class="row gx-5">
                    <div class="col-2">
                        <img class="rounded" v-if="personInfo.PicturePrimKey" :src="`/api/file/view/stbv_System_PersonsImages/${personInfo.PicturePrimKey}?mwidth=49`" style="max-width:80px;">
          
                    </div>
                    <div class="col-10">
                        <div class="text-truncate"><span>{{personInfo.Email}}</span></div>
                        <div><span>{{personInfo.MobileNo}}</span></div>
                        <div v-if="expandedInfo" class="text-truncate"><span>{{personInfo.Represents}}</span></div>
                        <div><small>{{personActivity}}</small></div>
                        <div v-if="isPersonExpired" class="text-danger"><small>{{$t("Person is expired")}}</small></div>
                    </div>
                </div>
            </template>
            <template v-else>
                {{$t('Loading...')}}
            </template>
        </div>
    </div>
</template>

<script lang="ts">
const PersonCache = new Map<number, IPersonInfo>();

export interface IPersonInfo {
    ID: string;
    Name: string;
    Email: string;
    Expired: string;
    MobileNo: string;
    Represents: string;
    DaysSinceLoggedIn: number;
    PicturePrimKey: string;
    PictureFileName: string;
};

export interface IPersonHoverProps {
    personId?: number;
    expandedInfo?: boolean;
}
</script>

<script setup lang="ts">
import { API } from 'o365-modules';
import { ref, computed, watch, onMounted, toDisplayString } from 'vue';
import { $t, logger } from 'o365-utils';

const props = defineProps<IPersonHoverProps>();

const showContent = ref(false);
const isLoaded = ref(false);
const personInfo = ref<IPersonInfo | null>(null);
const hoverElement = ref<HTMLElement | null>(null);
const personActivity = computed(() => {
    switch (personInfo.value?.DaysSinceLoggedIn) {
        case null:
            return '';
        case 0:
            return `${$t("Last active")}: ${$t("today")}`;
        case 1:
            return `${$t("Last active")}: ${personInfo.value.DaysSinceLoggedIn} ${$t("day ago")}`;
        default:
            return `${$t("Last active")}: ${personInfo.value.DaysSinceLoggedIn} ${$t("days ago")}`;
    }
});

const isPersonExpired = computed(() => {
  if (!personInfo.value?.Expired) {
    return false;
  }

  const [date, time] = personInfo.value.Expired.split(' ');
  const [day, month, year] = date.split('/');
  const [hours, minutes] = time.split(':');

  const expiredDate = new Date(year, month - 1, day, hours, minutes);
  
  return expiredDate < new Date();
});




function hoverShow() {
    showContent.value = true;
    if (isLoaded.value && personInfo.value?.ID != `${props.personId}`) {
        handleShow();
    }
}

function hoverHide() {
    showContent.value = false;
}

defineExpose({hoverElement, hoverHide, hoverShow});

async function loadPersonData(personId: number) {
    const result = await API.request({
        requestInfo: '/nt/api/data/sviw_System_Persons',
        method: 'POST',
        headers: new Headers({
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        }),
        body: JSON.stringify({
            "viewName": "sviw_System_Persons",
            "distinctRows": false,
            "skip": 0,
            "fields": [
                { name: "ID", type: "string" },
                { name: "Name", type: "string" },
                { name: "Email", type: "string" },
                { name: "Expired", type: "date" },
                { name: "MobileNo", type: "string" },
                { name: "Represents", type: "string" },
                { name: "DaysSinceLoggedIn", type: "number" },
                { name: "PicturePrimKey", type: "uniqueidentifier" },
                { name: "PictureFileName", type: "string" }
            ],
            "maxRecords": 1,
            "whereClause": `ID = ${personId}`,
            "masterDetailString": null,
            "filterString": null,
            "operation": "retrieve"
        })
    });
    return result != null ? result[0] : result;
}

async function handleShow() {
    isLoaded.value = false;
    personInfo.value = null;
    if (props.personId == null) { return; }
    let personData = PersonCache.get(props.personId);
    if (personData == null) {
        try {
            personData = await loadPersonData(props.personId);
            if (personData == null) { return; }
            PersonCache.set(props.personId, personData);
        } catch (ex) {
            logger.error(ex);
            return;
        }
    }
    personInfo.value = personData;
    isLoaded.value = true;
}

watch(() => props.personId, (_newPersonId) => {
    handleShow();
});

onMounted(() => {
    handleShow();
});

</script>
